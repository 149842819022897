<h2>{{iol.name}} - ({{iol.size}})</h2>
<h5><a href="{{topic.url}}">{{topic.name}}</a> - <a href="{{course.url}}">{{course.name}}</a></h5>
<h5><a *ngIf="prev" href="{{prev.url}}">&lt; Previous</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a *ngIf="next" href="{{next.url}}">Next &gt;&gt;&gt;</a></h5>
<h3>What you need to know</h3>
<ul>
  <li *ngFor="let kp of iol.knowledgePoints"><markdown [data]=kp katex></markdown></li>
</ul>

<div *ngIf="iol.quantities && iol.quantities.length">
  <h3>Quantities</h3>
  <app-quantities [quantities]="iol.quantities"></app-quantities>
</div>

<div *ngIf="iol.notes && iol.notes.length">
  <h3>Read these notes</h3>
  <app-notes [notes]="iol.notes"></app-notes>
</div>

<div *ngIf="iol.tools && iol.tools.length">
  <h3>Use these tools to experiment</h3>
  <app-notes [notes]="iol.tools"></app-notes>
</div>

<div *ngIf="iol.youtubeIds && iol.youtubeIds.length">
  <h3>Watch these videos</h3>
  <youtube-player *ngFor="let id of iol.youtubeIds" [videoId]="id"></youtube-player>
</div>

<div *ngIf="iol.questionSets && iol.questionSets.length">
  <h3>Answer these questions</h3>
  <app-notes [notes]="iol.questionSets"></app-notes>
</div>
